import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "♾-the-infinite-game",
      "style": {
        "position": "relative"
      }
    }}>{`♾ The Infinite Game`}<a parentName="h1" {...{
        "href": "#%E2%99%BE-the-infinite-game",
        "aria-label": "♾ the infinite game permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`This is the final leg of our Kernel Gaming Track adventure. Let's take a look back down the meandering path we've walked together. `}</p>
    <p>{`We started out discussing how Kernel fellows should think in complementary opposites, learn to identify patterns, and have humility. We played a few rounds of Among Us, where `}<em parentName="p">{`trust was broken... and rebuilt stronger.`}</em>{` `}</p>
    <Image src="/images/gaming-img/amongus.jpg" alt="Among Us" mdxType="Image" />
    <p>{`We jammed on the meaning of value and analyzed the business of games. We ruminated on freedom and open-source, and dissected game discoverability.`}</p>
    <p>{`For our last core discussion, there's no better way to close everything out than by circling back to complementary opposites, this time in the metaphysical context of the finite and infinite.`}</p>
    <h2 {...{
      "id": "finite-and-infinite-games",
      "style": {
        "position": "relative"
      }
    }}>{`Finite and Infinite Games`}<a parentName="h2" {...{
        "href": "#finite-and-infinite-games",
        "aria-label": "finite and infinite games permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Dr. James P. Carse wrote `}<a parentName="p" {...{
        "href": "/track-gaming/module-6/crafted/#finite-and-infinite-games-by-james-carse"
      }}><strong parentName="a">{`Finite and Infinte Games: A Vision of Life as Play and Possibility`}</strong></a>{` back in 1986, years before blockchains and the internet. The book starts with:`}</p>
    <blockquote>
      <p parentName="blockquote">{`There are at least two kinds of games. One could be called finite, the other infinite. A finite game is played for the purpose of winning, an infinite game for the purpose of continuing the play.`}</p>
    </blockquote>
    <p>{`A seemingly obvious sentence, but one that has profound implications when taken in the context of the games we play in life and in Web 3. Most of us frame the games we play as finite. These include goals such as reaching the top of leaderboards, becoming the the top grossing game on the app store, becoming the most funded gitcoin grant, getting X% of market share, becoming the Uber / AirBnb of Y.`}</p>
    <p>{`Dr. Carse's work aligns marvelously with how Kernel fellows should think. In particular, one point in the book harks back to `}<a parentName="p" {...{
        "href": "/learn/module-7/no-paradigm"
      }}>{`language`}</a>{` and `}<a parentName="p" {...{
        "href": "/conversation"
      }}>{`meaningful conversations`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`...the rules of an infinite game... are like the grammar of a living language, where those of a finite game are like the rules of debate. In the former case we observe rules as a way of continuing discourse with each other; in the latter we observe rules as a way of bringing the speech of another person to an end.`}</p>
    </blockquote>
    <p>{`The whole book has the potential of reframing how we think about the world, and I invite everyone to read through it. Below are some particularly salient points.`}</p>
    <p>{`On theatrical vs. dramatic:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Inasmuch as a finite game is intended for conclusion, inasmuch as its roles are scripted and performed for an audience, we shall refer to finite play as `}<em parentName="p">{`theatrical`}</em>{`. Although script and plot do not seem to be written in advance, we are always able to look back at the path followed to victory and say of the winners that they certainly knew how to act and what to say. Inasmuch as infinite players avoid any outcome whatsoever, keeping the future open, making all scripts useless, we shall refer to infinite play as `}<em parentName="p">{`dramatic`}</em>{`.`}</p>
    </blockquote>
    <p>{`On society:`}</p>
    <blockquote>
      <p parentName="blockquote">{`If we think of society as all that a people does under the veil of necessity, we must also think of it as a single finite game that includes any number of smaller games within its boundaries... The power of a society is determined by its victory over other societies in still larger finite games. Its most treasured memories are those of the heroes fallen in victorious battles with other societies.`}</p>
    </blockquote>
    <p>{`On revolution:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Whenever persons may walk on or off the field of play as they wish, there is such a confusion of participants that none can emerge as a clear victor. Who, for example, won the French Revolution?`}</p>
    </blockquote>
    <h2 {...{
      "id": "the-infinite-players",
      "style": {
        "position": "relative"
      }
    }}>{`The Infinite Players`}<a parentName="h2" {...{
        "href": "#the-infinite-players",
        "aria-label": "the infinite players permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`If an infinite game is played for the purpose of continuing play, who are the infinite players? `}</p>
    <blockquote>
      <p parentName="blockquote">{`The rules of an infinite game must change in the course of play. The rules are changed when the players of an infinite game agree that the play is imperiled by a finite outcome — that is, by the victory of some players and the defeat of others. The rules of an infinite game are changed to prevent anyone from winning the game and to bring as many persons as possible into the play.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Finite players play within boundaries; infinite players play with boundaries.`}</p>
    </blockquote>
    <p>{`In blockchains like Ethereum, these are the people working on the protocols and standards that make sure play goes on. These include the core devs that ensure that processes like the `}<a parentName="p" {...{
        "href": "/track-gaming/module-6/crafted/#ethereum-improvement-proposals"
      }}>{`Ethereum Improvement Proposals`}</a>{` are maintained.`}</p>
    <p>{`These also include the `}<a parentName="p" {...{
        "href": "https://www.w3.org/"
      }}>{`World Wide Web Consortium`}</a>{`, created to ensure the long-term growth of the open internet. Another is the `}<a parentName="p" {...{
        "href": "https://www.eff.org/"
      }}>{`Electronic Frontier Foundation`}</a>{`, working on protecting people's access to technology.`}</p>
    <p>{`With the current narratives of Web 3 being centered around money, efforts such as `}<a parentName="p" {...{
        "href": "/track-gaming/module-6/crafted/#web-monetization"
      }}>{`Web Monetization`}</a>{` are also sorely needed infinite players. By creating an open-source and universally accepted protocol for how to send money in browsers, we have another layer of protection in case even our blockchains start to centralize. `}</p>
    <p><a parentName="p" {...{
        "href": "/track-gaming/module-6/curated/#grant-for-the-web"
      }}>{`Grant for the Web`}</a>{` is another impressive effort. While the crypto news cycles are dominated by ever-increasing numbers, movements like `}<a parentName="p" {...{
        "href": "/track-gaming/module-5/crafted/#gitcoin-grants"
      }}>{`Gitcoin Grants`}</a>{` and Grant For The Web instead decide to focus on a more pressing matter: reshaping the currently one-sided finite games playing out in the attention economy.`}</p>
    <h2 {...{
      "id": "the-future-of-the-web",
      "style": {
        "position": "relative"
      }
    }}>{`The Future of The Web`}<a parentName="h2" {...{
        "href": "#the-future-of-the-web",
        "aria-label": "the future of the web permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Web 2 was won (or lost?) in the most unlikely of places. We didn't see it coming. Seemingly harmless social networks suddenly became the arbiters of attention and democracy, and an online book seller became the backbone of the internet. `}</p>
    <p>{`Striving towards our vision of Web 3 will be similarly fraught, and the struggle will be centred around keeping technologies like browsers and wallets open, and ensuring that new innovations like blockchains and cryptocurrency, defi, virtual goods, smart contracts, discoverability, monetization, and a multitude of other peripheral industries serve humane interests by following `}<a parentName="p" {...{
        "href": "/learn/module-5/prosocial-value"
      }}>{`humane, prosocial design patterns`}</a>{`.`}</p>
    <p>{`Countless finite games will be won and lost, as the participants all jockey for power. `}</p>
    <p>{`Where do we fit in this overarching Web 3 narrative? `}</p>
    <blockquote>
      <p parentName="blockquote">{`Power is a feature only of finite games. It is not dramatic but theatrical. How then do infinite players contend with power? Infinite play is always dramatic; its outcome is endlessly open... Infinite players look forward, not to a victory in which the past will achieve a timeless meaning, but toward ongoing play in which the past will require constant reinterpretation.`}</p>
    </blockquote>
    <p><strong parentName="p">{`Finite and Infinite Games`}</strong>{` ends succinctly with:`}</p>
    <blockquote>
      <p parentName="blockquote">{`There is but one infinite game.`}</p>
    </blockquote>
    <p>{`Open-ended, and left to our interpretation. Is there truly only one infinite game? And if there is, are we playing for or against it?`}</p>
    <h2 {...{
      "id": "remembrance",
      "style": {
        "position": "relative"
      }
    }}>{`Remembrance`}<a parentName="h2" {...{
        "href": "#remembrance",
        "aria-label": "remembrance permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We dedicate this module to the intergalactic memory of `}<a parentName="p" {...{
        "href": "https://web.archive.org/web/20201031025635/https://www.legacy.com/obituaries/recorder/obituary.aspx?pid=196925994"
      }}>{`Dr. James P. Carse`}</a>{`, whose work on `}<a parentName="p" {...{
        "href": "/track-gaming/module-6/crafted/#finite-and-infinite-games-by-james-carse"
      }}>{`Finite and Infinite Games`}</a>{` is the perfect guidepost as we reach the end of the Kernel Gaming Track.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Gardening is not outcome-oriented. A successful harvest is not the end of a gardener's existence, but only a phase of it. As any gardener knows, the vitality of a garden does not end with a harvest. It simply takes another form. Gardens do not die in the winter but quietly prepare for another season.`}</p>
    </blockquote>
    <h1 {...{
      "id": "-a-game-each-week",
      "style": {
        "position": "relative"
      }
    }}>{`🎮 A Game Each Week`}<a parentName="h1" {...{
        "href": "#-a-game-each-week",
        "aria-label": " a game each week permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <List mdxType="List">
      <Link to="https://store.steampowered.com/app/1070710/Kind_Words_lo_fi_chill_beats_to_write_to/" mdxType="Link">
        <h4 {...{
          "id": "kind-words",
          "style": {
            "position": "relative"
          }
        }}>{`Kind Words`}<a parentName="h4" {...{
            "href": "#kind-words",
            "aria-label": "kind words permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A game that epitomizes giving. Write anonymous notes about what currently troubles you, and receive heartwarming letters of support in response from real people all over the world. `}<strong parentName="p">{`Use your words to lift others and be lifted in return.`}</strong></p>
        <undefined><img {...{
            "src": "/images/gaming-img/ScrollingRequests.gif",
            "alt": "Kind Words"
          }}></img>
          <img {...{
            "src": "/images/gaming-img/WriteLetter.gif",
            "alt": "Kind Words"
          }}></img></undefined>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      